$primary_color_dark: #294863;
$primary_color: #424242;
$primary_color_light: #6489ac;
$secondary_color_dark: #FF6F00;
$secondary_color: #FFAB00;
$secondary_color_light: #FFECB3;
$tertiary_color: #C06C84;
$tertiary_color_light: #e6bed5;
$four_color_light: #bab3c1;
$four_color: #6C5B7B;
$white: #fff;
$white_strange: rgba(255,255,255,0.8);
$white_medium: rgba(255,255,255,0.3);
$white_strong: rgba(255,255,255,0.5);
$white_light: rgba(255,255,255,0.1);
$dark-color: #444;
$dark-color-light: #666;
$background: #fafafa;
$link_color: #8ab4f8;

$secondary_font_family: 'Oswald', sans-serif;
$primary_font_family: 'Open Sans', sans-serif;;

$black: #000;
$black_strong: rgba(0,0,0,0.7);
$black_medium: rgba(0,0,0,0.54);
$black_light: rgba(0,0,0,0.12);

$eat-drink-color: #B83746;
$sights-museums-color: #1EB2C5;
$natural-geographical-color: #009688;
$accommodation-color: #EF942D;
$leisure-outdoor-color: #C06C84;
$going-out-color: #8649BC;
$shopping-color: #759852;
$business-services-color: #8B572A;
$transport-color: #246AA5;
$search-color: #4A4A4A;

$error_color: rgb(242 77 77);
