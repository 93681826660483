// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gl-frontend-maps-primary: mat.define-palette(mat.$grey-palette, 800);
$gl-frontend-maps-accent: mat.define-palette(mat.$amber-palette, A700);


// The warn palette is optional (defaults to red).
$gl-frontend-maps-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gl-frontend-maps-theme: mat.define-light-theme((
  color: (
    primary: $gl-frontend-maps-primary,
    accent: $gl-frontend-maps-accent,
    warn: $gl-frontend-maps-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gl-frontend-maps-theme);

/* You can add global styles to this file, and also import other style files */

@import "variables";

html, body { height: 100%; }
body {
  background: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul{
  margin: 0;
  padding: 0;

  li{
    list-style: none;
  }
}

.main-title{
  font-family: 'Playball', cursive !important;
  font-size: 28px;
}

a{
  text-decoration: none;
  color: inherit;
}

.rounded-button{
  border-radius: 28px !important;
}

.main-container{
  max-width: 1440px;
  padding: 0 80px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  &.large {
    max-width: 1760px;
  }

  @media (min-width: 320px){
    padding: 0 16px;
  }

  @media (min-width: 744px){
    padding: 0 40px;
  }

  @media (min-width: 950px){
    padding: 0 40px;
  }

  @media (min-width: 1128px) {
    padding: 0 80px;
  }
}

h1{
  margin-bottom: 0;
}


h3{
  font-weight: 500 !important;
}

[mat-button], [mat-flat-button], [mat-stroked-button]{
  border-radius: 36px !important;
}

[mat-stroked-button][color="accent"]{
  border: 1px solid;
}


.gl-caption{
  color: $black_medium;
  font-size: 12px;
  //text-transform: uppercase;
  display: block;
}

.gl-link{
  color: $primary_color;
  cursor: pointer;
  text-decoration: none;

  &:hover{
    border-bottom: 1px solid $primary_color;
  }
}

.gl-title{
  color: $black_strong;
  font-size: 16px;
  line-height: 28px;
}

h6{
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.25px !important;
  font-weight: 500 !important;
  color: $black_strong
}

$rippleSize: 60px;

.ripple {
  position: absolute;
  margin-top: -42px;
  margin-left: -30px;
  z-index: 2;
  &:after {
    display: block;
    width: $rippleSize;
    height: $rippleSize;
    content: '';
    animation: rippleAnimation 1.2s ease-out infinite;
    opacity: 0;
    border-radius: 50%;
    box-shadow: 0 0 6px 3px $secondary_color;
  }

  &.eat-drink:after {
    box-shadow: 0 0 6px 3px $eat-drink-color;
  }

  &.sights-museums:after {
    box-shadow: 0 0 6px 3px $sights-museums-color;
  }

  &.natural-geographical:after {
    box-shadow: 0 0 6px 3px $natural-geographical-color;
  }

  &.accommodation:after {
    box-shadow: 0 0 6px 3px $accommodation-color;
  }

  &.leisure-outdoor:after {
    box-shadow: 0 0 6px 3px $leisure-outdoor-color;
  }

  &.going-out:after {
    box-shadow: 0 0 6px 3px $going-out-color;
  }

  &.shopping:after {
    box-shadow: 0 0 6px 3px $shopping-color;
  }

  &.business-services:after {
    box-shadow: 0 0 6px 3px $business-services-color;
  }

  &.transport:after {
    box-shadow: 0 0 6px 3px $transport-color;
  }

  &.search:after {
    box-shadow: 0 0 6px 3px $search-color;
  }
}
@keyframes rippleAnimation {
  0% {
    transform: scale(.1, .1);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.mat-tooltip{
  background: #444;
  font-size: 14px;
}

.gl-icon{
  height: 16px !important;
  line-height: 16px !important;
  width: 16px !important;
}

.category-image{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &.eat-drink {
    background-color: $eat-drink-color;
  }

  &.sights-museums {
    background-color: $sights-museums-color;
  }

  &.natural-geographical {
    background-color: $natural-geographical-color;
  }

  &.accommodation {
    background-color: $accommodation-color;
  }

  &.leisure-outdoor {
    background-color: $leisure-outdoor-color;
  }

  &.going-out {
    background-color: $going-out-color;
  }

  &.shopping {
    background-color: $shopping-color;
  }

  &.business-services {
    background-color: $business-services-color;
  }

  &.transport {
    background-color: $transport-color;
  }

  &.search {
    background-color: $search-color;
  }
}

.mat-drawer-backdrop{
  height: 100vh !important;
  width: 100vw !important;
  position: fixed !important;
}

.mat-slider{
  ::ng-deep .mat-slider-thumb{
    background: $secondary_color;
  }

  ::ng-deep .mat-slider-track-fill{
    background: $secondary_color;
  }
}

.ng-scroll-viewport{
  contain: initial !important;
}

.hidden{
  display: none !important;
}

.hide-scrollbar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.icon-label{
  mat-icon{
    margin-left: -8px;
    margin-right: 8px;
  }
}

.mat-paginator{
  background: transparent;
}

@media (max-width: 744px){
  .mat-dialog-title{
    margin: 0 !important;
  }
  .mat-dialog-container{
    height: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    width: 100vw;
    border-radius: 0 !important;
  }
  .mat-dialog-content{
    max-height: none !important;
    height: calc(100% - 69px) !important;
  }
}

.logo{
  height: 44px;
  width: 44px;
  margin: 9px auto 0;
  mat-icon{
    height: 44px;
    width: 44px;
  }
}

